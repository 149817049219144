import { useCallback, useEffect, useState } from "react";

export function useMobileMenu() {
  const [windowOffset, setWindowOffset] = useState(0);
  const [activeDrawer, setActiveDrawer] = useState(null);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // Fix to prevent background scrolling and bottom nav overlap on iOS
  useEffect(() => {
    const isMobileView = window.innerWidth < 992;
    if (isMobileView && activeDrawer && !windowOffset) {
      setWindowOffset(window.scrollY);
      document.documentElement.setAttribute("style", `height: 100vh;`);
      document.body.setAttribute("style", `position: fixed;`);
    } else {
      document.documentElement.setAttribute("style", "");
      document.body.setAttribute("style", "");
      window.scrollTo(0, windowOffset);
      setWindowOffset(0);
    }
  }, [activeDrawer, windowOffset]);

  // Expand search option and collapse other menu options
  function onClickSearch() {
    setExpandedMenu(null);
    setIsSearchVisible((value) => !value);
  }

  // Collapse / clear all choices on page option click
  function onSelectMenuOption() {
    setActiveDrawer(null);
    setExpandedMenu(null);
    setIsSearchVisible(false);
  }

  // Expand menu option
  function onExpandMenuOption(option) {
    setExpandedMenu(option);
    setIsSearchVisible(false);
  }

  // Expand or collapse mobile menus and clear any selections
  const onToggleDrawer = useCallback(
    (drawer) => {
      if (drawer === activeDrawer) {
        setActiveDrawer(null);
      } else {
        setActiveDrawer(drawer);
      }
      setExpandedMenu(null);
      setIsSearchVisible(false);
    },
    [activeDrawer]
  );
  return {
    expandedMenu,
    activeDrawer,
    isSearchVisible,
    onClickSearch,
    onSelectMenuOption,
    onExpandMenuOption,
    onToggleDrawer,
  };
}
