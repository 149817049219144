import Image from "next/image";
import Link from "next/link";
import { useState } from "react";

import Icon from "@/components/icon/Icon";
import { STORE_PATH } from "@/config/constants";
import labels from "@/config/labels";
import BNLogo from "@/public/betting-news-logo.svg";

import DesktopNavMenu from "./DesktopNavMenu";
import Search from "./Search";

const DesktopNav = ({ menus }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const linkClassName =
    "flex flex-row items-center py-2 px-4 text-[0.925rem] xl:text-base text-white font-semibold whitespace-nowrap tracking-wide capitalize";

  return (
    <nav
      className={"sticky top-0 z-20 hidden w-full bg-blue px-4 py-0 xl:block"}
    >
      <div className="container flex h-[88px] flex-row items-center px-0 xl:max-w-screen-2xl">
        <Link aria-label="Betting News" href="/">
          <Image
            src={BNLogo}
            alt="Betting News Logo"
            title="Betting News"
            height={38}
            loading="eager"
            priority
            width={168}
            className="xl:hidden"
          />
          <Image
            src={BNLogo}
            alt="Betting News Logo"
            title="Betting News"
            height={38}
            width={200}
            loading="eager"
            priority
            className="hidden h-[36px] w-[180px] xl:inline-block"
          />
        </Link>
        <div className="mt-3 flex flex-row p-2">
          {menus &&
            Object.entries(menus).map(([menuKey, menu]) => (
              <DesktopNavMenu key={menuKey} menu={menu} name={menuKey} />
            ))}
          <Link className={linkClassName} href={STORE_PATH} target="_blank">
            {labels.storeLabel}
          </Link>
          <div>
            <button
              aria-label={labels.navBarSearchTitle}
              onClick={() => setIsSearchVisible((value) => !value)}
              className={linkClassName}
            >
              <Icon
                name="search"
                className="ml-0 mt-1 -scale-x-100"
                color="currentColor"
                height={20}
                width={20}
              />
            </button>
            {isSearchVisible && (
              <Search onSubmitSearch={() => setIsSearchVisible(false)} />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DesktopNav;
