import { useAppContext } from "@/hooks/useAppContext";
import { addHorseTracksToNav } from "@/utilities/horseTracks";

import DesktopNav from "./components/DesktopNav";
import MobileNav from "./components/MobileNav";

const Nav = ({ horses }) => {
  const { topmenu } = useAppContext();
  const navMenus = addHorseTracksToNav(horses, topmenu ?? {});
  return (
    <>
      <DesktopNav menus={navMenus} />
      <MobileNav menus={navMenus} />
    </>
  );
};

export default Nav;
