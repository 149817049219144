import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import labels from "@/config/labels";

const Search = ({ isMobileDrawer, onSubmitSearch }) => {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState("");

  // Submit search and then clear search term on enter
  function onKeyUp(event) {
    if (event?.key === "Enter") {
      event?.preventDefault();
      if (onSubmitSearch) {
        onSubmitSearch();
      }
      setSearchTerm("");
      router.push(`/search?term=${encodeURIComponent(event?.target?.value)}`);
    }
  }

  // Store search term on input change
  function onChange(event) {
    setSearchTerm(event?.target?.value);
  }

  // Submit search and then clear search term
  const onSubmit = useCallback(() => {
    if (onSubmitSearch) {
      onSubmitSearch();
    }
    const encodedSearmTerm = encodeURIComponent(searchTerm);
    setSearchTerm("");
    router.push(`/search?term=${encodedSearmTerm}`);
  }, [onSubmitSearch, router, searchTerm]);

  const containerClassName = isMobileDrawer
    ? "block p-4 bg-link-blue -mx-4"
    : "absolute left-0 top-[72px] w-full p-4 bg-link-blue z-[2]";

  return (
    <div className={containerClassName}>
      <div className="flex flex-row items-center justify-center">
        <input
          className="max h-[44px] w-full min-w-[200px] max-w-[500px] rounded-lg border border-grey-200 bg-off-white px-4 py-[5px] font-normal text-dark"
          type="text"
          autoFocus
          onChange={onChange}
          onKeyUp={onKeyUp}
          placeholder={labels.searchPlaceholder}
          value={searchTerm}
        />
        <button
          aria-label={labels.searchButton}
          className="ml-2 rounded-full bg-blue px-5 py-3 font-heading text-sm font-normal text-white hover:bg-dark-blue"
          onClick={onSubmit}
        >
          {labels.searchButton}
        </button>
      </div>
    </div>
  );
};

export default Search;
