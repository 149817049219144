import Link from "next/link";

const FooterList = ({ paths, title }) => (
  <div className="text-xs text-grey-200">
    {paths && (
      <>
        {Object.entries(paths).map(([pathName, path], index) =>
          index === 0 ? (
            <h4 key={pathName} className="mb-4 whitespace-normal">
              <Link
                aria-label={`${title} ${pathName}`}
                href={path}
                className="font-bold underline decoration-red"
              >
                {pathName}
              </Link>
            </h4>
          ) : null
        )}
        <ul>
          {Object.entries(paths).map(([pathName, path], index) =>
            index > 0 ? (
              <li key={pathName} className="mb-4 whitespace-normal">
                <Link aria-label={`${title} ${pathName}`} href={path}>
                  {pathName}
                </Link>
              </li>
            ) : null
          )}
        </ul>
      </>
    )}
  </div>
);

export default FooterList;
