import Image from "next/image";
import Link from "next/link";

import Icon from "@/components/icon/Icon";
import {
  ABOUT_US_PATH,
  IS_PROD,
  PRIVACY_POLICY_PATH,
  RESPONSIBLE_GAMING,
  SOCIAL_LINKS,
} from "@/config/constants";
import labels from "@/config/labels";
import { FOOTER_LEAGUE_MENUS } from "@/config/menus";
import Logo18 from "@/public/18+-logo.png";
import BNLogo from "@/public/betting-news-logo.svg";
import GambleAware from "@/public/gamble-aware-main.png";
import GoogleIcon from "@/public/googlenews-white.png";
import { getFooterYear } from "@/utilities/dates";

import Newsletter from "../sidebar/components/Newsletter";
import FooterList from "./components/FooterList";

const Footer = () => {
  return (
    <footer className="w-full bg-blue py-13 text-grey-200">
      <div className="container">
        <div>
          <div className="sm:grid-rows-auto grid grid-cols-2 gap-x-3 gap-y-3 sm:gap-x-2 sm:gap-y-4 xl:grid-cols-6">
            {Object.entries(FOOTER_LEAGUE_MENUS).map(
              ([leagueName, league], index) => (
                <div
                  key={leagueName}
                  className={`${
                    index === 6
                      ? "row-start-5 sm:col-start-3 sm:row-start-2"
                      : ""
                  }`}
                >
                  <FooterList paths={league} title={leagueName} />
                </div>
              )
            )}
            <div className="flex flex-col flex-wrap justify-between text-sm sm:col-span-3 xl:flex-row">
              <div className="mb-4 h-auto xl:w-[45%]">
                <div>
                  <p className="font-bold">{labels.footerFollowSocialPrefix}</p>
                </div>
                <div className="mt-4 flex cursor-pointer flex-row flex-wrap gap-4">
                  {Object.entries(SOCIAL_LINKS).map(([type, social]) => (
                    <Link
                      key={type}
                      aria-label={type}
                      target="_blank"
                      href={social?.url ?? ""}
                    >
                      <Icon
                        className="h-8 w-8"
                        color="white"
                        height={32}
                        name={type}
                        width={32}
                      />
                    </Link>
                  ))}
                  <Link
                    href="https://news.google.com/publications/CAAqBwgKMPXmpAswuPG8Aw"
                    target="_blank"
                  >
                    <Image
                      src={GoogleIcon}
                      width={32}
                      height={32}
                      alt="Google News icon"
                    />
                  </Link>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="relative right-4 lg:bottom-5">
                  <Newsletter />
                </div>
              </div>
            </div>
          </div>
          <div className="block lg:hidden">
            <div className="relative right-4">
              <Newsletter />
            </div>
          </div>
        </div>
        <div className="my-6 w-full border-t border-white/50 xl:my-6" />
        <div>
          <Image
            className="mt-4 h-[35px] w-[180px] sm:mt-0"
            src={BNLogo}
            alt="Sports-Betting-News"
            height={35}
            width={180}
            unoptimized
          />
        </div>
        <div>
          {labels.footerLogoText.split("\n").map((line, index) => (
            <p key={index} className={`${index === 1 ? "py-4" : ""}`}>
              {line}
            </p>
          ))}
        </div>
        <div className="my-6 w-full border-t border-white/50 xl:my-6" />
        <div className="-mx-4 flex flex-col gap-5 px-5 text-xs">
          <div className="grid grid-cols-3 items-center justify-items-start  xl:mb-6 xl:justify-items-center">
            <Link
              aria-label={labels.footerPrivacyPolicyLink}
              className="self-center capitalize hover:underline"
              href={PRIVACY_POLICY_PATH}
            >
              {labels.footerPrivacyPolicyLink}
            </Link>
            <Link
              aria-label={labels.footerAboutUsLink}
              className="capitalize hover:underline"
              href={ABOUT_US_PATH}
            >
              {labels.footerAboutUsLink}
            </Link>
            <p className="hidden xl:block">
              {`${labels.footerCopyrightDesktopPrefix} ${
                labels.footerCopyrightStartYear
              } ${getFooterYear()} ${labels.footerCopyrightDesktopPostfix}`}
            </p>
          </div>
          <p className="block xl:hidden">
            {`${labels.footerCopyrightDesktopPrefix} ${
              labels.footerCopyrightStartYear
            } ${getFooterYear()} ${labels.footerCopyrightDesktopPostfix}`}
          </p>
          <div>
            <Link
              aria-label="Gamble Aware"
              href="https://www.begambleaware.org/"
              target="_blank"
            >
              <div className="flex flex-row items-center">
                <Image
                  className="mt-4 sm:mt-0"
                  src={Logo18}
                  alt="18 +"
                  height={30}
                  width={30}
                />
                <Image
                  className="relative right-2 mt-4 sm:mt-0"
                  src={GambleAware}
                  alt="Gamble Aware logo"
                  height={50}
                  width={150}
                />
              </div>
            </Link>
            <p className="font-body">
              {labels.footerGambleAware}{" "}
              <Link
                href={RESPONSIBLE_GAMING}
                aria-label={labels.footerResponsibleGaming}
              >
                {labels.footerResponsibleGaming}
              </Link>
            </p>
          </div>
          <p className="mb-4 text-start font-body leading-normal xl:text-justify">
            {labels.footerDisclaimer}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
