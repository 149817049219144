import Image from "next/image";
import Link from "next/link";
import AnimateHeight from "react-animate-height";

import ConsensusPicks from "@/components/consensusPicks/ConsensusPicks";
import Icon from "@/components/icon/Icon";
import { SOCIAL_GOOGLE, SOCIAL_LINKS, STORE_PATH } from "@/config/constants";
import labels from "@/config/labels";
import { useMobileMenu } from "@/hooks/useMobileMenu";
import useScreenWidth from "@/hooks/useScreenWidth";
import BNLogo from "@/public/betting-news-logo.svg";

import MobileNavMenu from "./MobileNavMenu";
import Search from "./Search";

const MOBILE_DRAWERS = {
  MENU: "menu",
  PICKS: "picks",
};

const MobileNav = ({ menus }) => {
  const {
    activeDrawer,
    expandedMenu,
    isSearchVisible,
    onClickSearch,
    onExpandMenuOption,
    onSelectMenuOption,
    onToggleDrawer,
  } = useMobileMenu();
  const linkMenuClassName =
    "relative w-full flex flex-row py-2 items-center text-white text-left font-semibold whitespace-nowrap tracking-wide leading-[28px] capitalize";

  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 590;

  return (
    <nav
      className={`sticky top-0 z-20 block w-full bg-blue px-4 py-2 xl:hidden ${
        activeDrawer === MOBILE_DRAWERS.MENU ? "pr-0" : ""
      }`}
    >
      <div className="flex w-full flex-row items-center justify-between px-0">
        <Link aria-label="Betting News" href="/" onClick={onSelectMenuOption}>
          <Image
            src={BNLogo}
            alt="Betting News Logo"
            title="Betting News"
            height={38}
            width={168}
          />
        </Link>
        <button
          aria-label={labels.mobileNavMenu}
          className={`nav-bar-menu-toggle -my-2 -ml-6 flex h-[56px] flex-col items-center justify-center font-heading text-xs uppercase text-white ${
            activeDrawer === MOBILE_DRAWERS.MENU ? "active w-[90px]" : ""
          }`}
          onClick={() => onToggleDrawer(MOBILE_DRAWERS.MENU)}
        >
          <Icon
            name={
              activeDrawer === MOBILE_DRAWERS.MENU
                ? "mobileNavClose"
                : "mobileNavMenu"
            }
            color="currentColor"
            height={28}
            width={25}
            className="text-[28px]"
          />
          {labels.mobileNavMenu}
        </button>
      </div>
      <AnimateHeight
        className="absolute left-0 top-[56px] w-screen xl:hidden"
        duration={500}
        height={activeDrawer ? "auto" : 0}
      >
        {activeDrawer === MOBILE_DRAWERS.MENU && (
          <div className="nav-bar-mobile-menu w-full overflow-y-auto bg-dark-blue p-4">
            {menus &&
              Object.entries(menus).map(([menuKey, menu]) => (
                <MobileNavMenu
                  key={menuKey}
                  expandedMenuOption={expandedMenu}
                  menu={menu.menuOptions}
                  name={menuKey}
                  onExpandMenuOption={onExpandMenuOption}
                  onSelectMenuOption={onSelectMenuOption}
                />
              ))}
            <Link
              className={`${linkMenuClassName}`}
              href={STORE_PATH}
              target="_blank"
            >
              <Icon
                className="mr-5 text-white"
                height={16}
                name="store"
                color="currentColor"
                width={20}
              />
              {labels.storeLabel}
            </Link>
            <button
              aria-label={labels.navBarSearchTitle}
              onClick={onClickSearch}
              className={linkMenuClassName}
            >
              <Icon
                className="mr-5 text-white"
                height={16}
                name="search"
                color="currentColor"
                width={20}
              />
              {labels.navBarSearchTitle}
            </button>
            {isSearchVisible && (
              <Search isMobileDrawer onSubmitSearch={onSelectMenuOption} />
            )}
            <div className="ml-10 flex flex-row py-2">
              <p className="text-white">{labels.footerSocialPrefix}</p>
              <div
                className={`flex gap-4  ${isMobile ? " grid grid-cols-4" : ""}`}
              >
                {Object.entries(SOCIAL_LINKS).map(([type, social]) => (
                  <Link
                    key={type}
                    aria-label={type}
                    className="ml-2"
                    href={social.url}
                    target="_blank"
                  >
                    <Icon
                      className={`h-[26px] w-8 ${type === "instagram" ? "insta-icon" : ""}`}
                      color={social.color}
                      height={32}
                      name={type}
                      width={26}
                    />
                  </Link>
                ))}
                {Object.entries(SOCIAL_GOOGLE).map(([type, social]) => (
                  <Link
                    key={type}
                    aria-label={type}
                    className="ml-2"
                    target="_blank"
                    href={social.url}
                  >
                    <Icon
                      className="h-[26px] w-8"
                      color={social.color}
                      height={32}
                      name={type}
                      width={26}
                    />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
        {activeDrawer === MOBILE_DRAWERS.PICKS && (
          <div className="nav-bar-mobile-menu w-full bg-site-bg p-4">
            <ConsensusPicks onSelectGame={onSelectMenuOption} />
          </div>
        )}
      </AnimateHeight>
    </nav>
  );
};

export default MobileNav;
